#footer {
  margin-top: $footer-vertical-spacing-top;
  text-align: center;
  background-color: $asdrgrey;
  color: white;
  padding-top: 2rem;
  font-size: smaller;

  a {
    color: white;
    text-decoration: underline;
  }
  h3 {
    color: $white;
  }

  img#footer_logo {
    height: 3.5rem;
    min-width: 125px;
  }

  img#sm_li {
    width: 1.3rem;
  }
  span.socialm{ 
    img.ui.image {
      display: inline-block;
      width: 1.5rem;
      margin-right: 0.3rem;
    }
  }
  span.vbar {
    a {
      text-decoration: none;
    }
    ul {
      padding: 0;
    }
    li {
      border-right: white solid 1px;
      display: table-cell;
      padding: 0rem 0.5rem 0rem 0.5rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .footer {
    padding: 4.2rem 2rem;
    background-color: $lightgrey;
    font-size: 18px;

    a.powered-by,
    .footer-branding {
      font-size: 14px;
    }

    .footer-message {
      font-weight: $bold;
      a {
        font-weight: inherit;
        text-decoration: underline;
      }
    }

    ul {
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-top: 1.4rem;
      list-style: none;

      li {
        padding: 0 7px;
        border-right: 1px solid #205c90;
        font-size: 18px;

        &:last-of-type {
          border: none;
        }
      }
    }

    .logo {
      margin: 5rem 0 1.8rem 0;
    }
  }
}
