body { 
  a {
    color: $asdrgreen;
    text-decoration: underline;
    &.hover {
      color: $asdrgreen;
      text-decoration: none;
    }
  }
}
.ui.secondary.pointing.menu { 
  .active.item {
    border-color: $asdrblue;
    color: $asdrgreen;

    &.hover {
      border-color: $asdrblue;
      color: $asdrgreen;
    }
  }
}
.documentFirstHeading {
  &::before {
    border-bottom: 2px solid $plonegrey;
    bottom: -2px;
  }
}


.ui.secondary.segment {
    background: none;
    color: #052e37;
}

.ui.vertical.segment {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background: none transparent;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: none;
}

body.contenttype-plone-site,
body.contenttype-lrf,
body.view-contentsview {
  #main .breadcrumbs {
    display: none;
  }
}

#main .breadcrumbs {
  padding: 20px 0;
  display: flex;
  align-items: center;


    a.section {
      color: $asdrgreen;
    }

    .divider {
      display: inline-block;
      opacity: .7;
      margin: 0 .5rem 0;
      font-size: .92857143em;
      color: $asdrgreen;
      vertical-align: baseline;
    }


    .divider {
      &::before {
        color: $black;
        font-size: 7px;
      }
    }

    .home {
      margin-right: 0.5em;

      svg {
        margin-bottom: -4px;
      }
    }

    .section {
      margin: 0 7px 0 4px;
      color: $asdrgreen;
      text-decoration: none;
      @include marginal-description();

      &.active {
        text-decoration: underline;
      }
    }
  
}
